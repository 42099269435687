import { cleanClone } from '@/components/utils/common'
import { urlTypesLoad } from '@/config/common'

export default {
  props: {
    paramsToUpload: [Object, Array],
    commandType: {
      default: 'exporting',
      type: String
    }
  },
  data () {
    return {
      isChecking: false,
      status: null,
      file: null,
      timeout: 5000,
      show: false,
      error: null,
      idForCheck: null,
      config: {},
      fileName: null
    }
  },
  computed: {
    statusList () {
      return [
        { value: 'wait', label: 'Ожидание', color: 'orange' },
        { value: 'success', label: 'Завершено', color: 'green' },
        { value: 'error', label: 'Ошибка', color: 'red' }
      ]
    }
  },
  methods: {
    canDownload (response) {
      return !!response.data
    },
    async start () {
      this.show = true
      this.isChecking = true
      const response = await this.$store.dispatch('api/post', {
        url: urlTypesLoad.start,
        data: this.paramsToUpload
      })
      if (response.success) {
        this.status = this.statusList.find(status => status.value === 'wait')
        this.file = response.data
        this.idForCheck = response.data.id
        setTimeout(async () => this.StatusCheck(), this.timeout)
      } else {
        this.status = this.statusList.find(status => status.value === 'error')
        this.isChecking = false
      }
      return true
    },
    async StatusCheck () {
      if (this.status && this.status.value === 'wait') {
        const response = await this.$store.dispatch('api/post', {
          url: urlTypesLoad.status,
          data: {
            commandType: this.commandType,
            params: { 'id': this.idForCheck }
          }
        })
        if (response.success && !response.data?.error) {
          if (this.canDownload(response) && response.data?.fileName) {
            this.status = this.statusList.find(status => status.value === 'success')
            this.isChecking = false
            this.fileName = response.data.fileName
            await this.download()
          } else {
            setTimeout(async () => this.StatusCheck(), this.timeout)
          }
        } else {
          this.status = this.statusList.find(status => status.value === 'error')
          this.isChecking = false
          this.error = response.data.error
        }
      }
      return true
    },
    async download () {
      const response = await this.axios({
        url: urlTypesLoad.download,
        method: 'POST',
        data: {
          data: {
            path: this.fileName
          }
        },
        responseType: 'blob'
      })
      if (response) {
        const blob = response.data // приходит сразу Blob, форматировать не надо
        const fileArray = this.fileName?.split('/')
        const fileName = fileArray[fileArray?.length - 1] //достаю имя из export/10/Московская.zip

        const url = window.URL.createObjectURL(blob)

        const downloadLink = document.createElement('a')
        downloadLink.href = url
        downloadLink.download = fileName || 'Без названия'

        downloadLink.click()

        window.URL.revokeObjectURL(url) // если коротко: забывание URL-адреса объекта для браузера
      }
      return true
    }
  }
}
